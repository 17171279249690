<template lang="pug">
//- div
//-   h1 Hello, Vue 3! {{oss_path}}
//- link(href='/css/home-page.css' rel='stylesheet')
//- script(type='text/javascript' src='/js/jquery.min.js')
//- script(src='/js/home-page.js')
//- script(src='/js/jspdf.min.js')
//- script(src='/js/html2canvas.min.js')
component(:is="componentName" :payment="paymentInvoice" style="overflow:auto;" :invoiceBase="invoiceBase" :oss-path="oss_path") 
</template>
<script>
import '@/assets/css/home-page.css';
// import extraFeePayment from './components/extraFeePayment.vue';
import ReceiptPayment from './components/receiptPayment.vue';
import receiptPaymentBigger52 from './components/receiptPaymentBigger52.vue';
// import refundPayment from './components/refundPayment.vue';
// import { getReceiptInfo } from '@/api';
import config from '@/global/config';

// defineComponent({
//   ReceiptPayment,
// });
// const router = useRoute();
// const componentName = ref();
// const paymentInvoice = ref({
//   members: [],
//   packageCode: '',
//   title: '',
// });
const componentMap = {
  payment: 'ReceiptPayment',
  extraFee: 'extraFeePayment',
  refund: 'refundPayment',
};
const invoiceDataVoNameMap = {
  payment: 'paymentInvoiceVo',
  extraFee: 'extraFeeInvoiceVo',
  refund: 'refundInvoiceVo',
};
// const showtest = ref(true);

// const invoiceBase = computed(() => paymentInvoice.value.invoiceBaseVo || {});
// const oss_path = config.oss_path;
export default {
  props: {},
  components: {
    // refundPayment,
    ReceiptPayment,
    receiptPaymentBigger52,
    // extraFeePayment
  },
  data() {
    return {
      oss_path: config.oss_path,
      paymentInvoice: {
        members: [],
        packageCode: '',
        title: '',
      },
      componentName: '',
    };
  },
  computed: {
    invoiceBase() {
      return this.paymentInvoice.invoiceBaseVo || {};
    },
  },
  created() {
    // console.log(this.$route);
    this.$store
      .dispatch('orderModule/getInvoiceInfoById', this.$route.params.id)
      .then((res) => {
        console.log(res, 99999);
        const data = res.data.returnData;
        const { invoiceType } = data;
        const voName = invoiceDataVoNameMap[invoiceType];
        this.paymentInvoice = data[voName];
        this.paymentInvoice.members = data[voName].receiptMemberVos || [];
        if (invoiceType == 'extraFee') {
          this.paymentInvoice.items = JSON.parse(this.paymentInvoice.items);
        }
        if (invoiceType == 'payment') {
          let memberZero = data[voName].receiptMemberVos[0];
          this.paymentInvoice.packageCode = memberZero.packageCode;
          this.paymentInvoice.title = memberZero.packageName;
        }
        if (this.paymentInvoice.members.length > 52) {
          this.componentName = "receiptPaymentBigger52";
        } else {
          this.componentName = componentMap[invoiceType];
        }
      });
    // getReceiptInfo(this.$router.params.id).then((res) => {
    //   console.log(res);
    //   const data = res.returnData;
    //   const { invoiceType } = data;
    //   const voName = invoiceDataVoNameMap[invoiceType];
    //   paymentInvoice.value = data[voName];
    //   paymentInvoice.value.members = data[voName].receiptMemberVos || [];
    //   if (invoiceType == 'extraFee') {
    //     paymentInvoice.value.items = JSON.parse(paymentInvoice.value.items);
    //   }
    //   if (invoiceType == 'payment') {
    //     let memberZero = data[voName].receiptMemberVos[0];
    //     paymentInvoice.value.packageCode = memberZero.packageCode;
    //     paymentInvoice.value.title = memberZero.packageName;
    //   }
    //   if (paymentInvoice.value.members.length > 52) {
    //     componentName.value = receiptPaymentBigger52;
    //   } else {
    //     componentName.value = componentMap[invoiceType];
    //   }
    // });
  },
  methods: {},
};
// // css
// link(href='/css/home-page.css' rel='stylesheet')
// // js
// script(type='text/javascript' src='/js/jquery.min.js')
// script(src='/js/home-page.js')
// script(src='/js/jspdf.min.js')
// script(src='/js/html2canvas.min.js')
// require("@/assets/js/jquery.min.js");
// import "@/assets/js/home-page.js";
// import "@/assets/js/jspdf.min.js";
// import "@/assets/js/html2canvas.min.js";
</script>
<style></style>
